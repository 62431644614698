section.login-page {
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;

  .centered-box {
    box-sizing: border-box;
    display: grid;
    align-content: center;
    justify-content: center;
    height: 100%;

    form.log-in {
      box-shadow: 1px 0px 46px 0px rgba(125, 125, 125, 0.25);
      padding: 40px;
      width: 500px;
      max-width: calc(100vw - 20px);
      border: solid 1px var(--border-color);
      border-radius: 4px;

      .row {
        .checkbox-wrapper {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          column-gap: 6px;
          justify-content: start;

          > label {
            cursor: pointer;
            font-size: 0.8em;
          }
        }

        input[type='checkbox'] {
          cursor: pointer;
          width: 1.2em;
          height: 1.2em;
        }

        .input-with-icon {
          position: relative;

          img {
            position: absolute;
            width: 24px;
            height: 24px;
            right: 10px;
            top: 50%;
            opacity: 30%;
          }
        }

        input {
          padding-left: 10px;
          padding-right: 40px;

          background-size: 24px 24px;
          background-repeat: no-repeat;
          background-position: right 10px center;
        }
      }
    }
  }
}
