section.app-page {
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;

  .body-content {
    overflow: scroll;
    display: grid;
    grid-template-rows: 1fr max-content;

    > * {
      padding: 20px;
    }

    .links-table {
      height: 100%;
      width: 100%;
      max-width: 100vw;
      overflow: scroll;
      border-bottom: solid 1px var(--border-color);

      .sticky-wrapper {
        margin: 0;
        padding: 0; //should not padd to support sticky header
        height: 100%;
        width: 100%;
        max-width: 100vw;
        overflow: scroll;
        border-top: solid 1px var(--gray-bg-color);

        table {
          table-layout: fixed;
          width: 100%;
          border-collapse: collapse;
          border: solid 1px var(--gray-bg-color);
          border-top: 0;

          thead tr {
            border-bottom: solid 1px var(--gray-bg-color);
            position: sticky;
            top: -1px;
            background: white;
          }

          tr {
            line-height: 1.5em;

            &:nth-child(2n) {
              background-color: var(--gray-bg-color);
            }

            td,
            th {
              width: 120px;

              &.expanding {
                width: calc(100% - 242px);
                max-width: calc(100% - 242px);
                overflow: hidden;
              }

              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              padding: 4px 10px;
              font-size: 0.8em;
              border-radius: 0;
              border-right: solid 1px var(--border-color);
              &:first-child {
                border-left: solid 1px var(--border-color);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  section.app-page {
    .body-content {
      .links-table {
        table {
          tr {
            td,
            th {
              width: 80px;
              &.expanding {
                width: calc(100% - 162px);
                max-width: calc(100% - 162px);
              }
            }
          }
        }
      }
    }
  }
}
