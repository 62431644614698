.site-header {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 10px;
  background: var(--gray-bg-color);
  border-bottom: solid var(--border-color) 1px;
  border-radius: 0;
  align-items: center;

  > * {
    padding-top: 5px;
    padding-bottom: 5px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }

  > .login {
    border-left: solid 1px var(--border-color);
    border-radius: 0;
    padding-left: 20px;
    height: 100%;
    align-items: center;
    display: grid;
    cursor: pointer;

    &:hover {
      background-color: var(--light-gray-color);
    }
  }

  > .version {
    align-self: end;
    color: var(--very-dark-gray-color);
    font-size: 0.7em;
  }

  > .logo {
    border-right: solid 1px var(--border-color);
    border-radius: 0;
    padding-right: 20px;

    display: grid;
    justify-content: left;
    justify-items: center;
    grid-template-areas:
      'logo brand'
      'slogen slogen';

    align-items: center;
    column-gap: 5px;

    > img.logo-image {
      grid-area: logo;
      width: 30px;
      height: auto;
    }

    .brand {
      grid-area: brand;
      font-size: 1.6em;
      font-weight: bold;
      line-height: 1em;
    }
    .slogen {
      grid-area: slogen;
      color: var(--very-dark-gray-color);
    }
  }
}
