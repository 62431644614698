@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
:root {
  --border-color: #e0e0e0;
  --gray-bg-color: #f0f0f0;
  --light-gray-color: #d0d0d0;
  --dark-gray-color: #a0a0a0;
  --very-dark-gray-color: #606060;
  --action-color: #43a6eb;
  --alert-color: red;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 18px;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input[type='button'],
input[type='submit'],
a {
  cursor: pointer;
}

small,
.small {
  font-size: 0.8em;
}

.clickable {
  cursor: pointer;
}

.error {
  color: var(--alert-color);
}

h1 {
  font-weight: bold;
}

button.action {
  border-radius: 20px;
  background-color: var(--action-color);
  color: white;
  font-weight: bold;
}

form.normal-form {
  display: grid;
  gap: 20px;

  &.no-gap {
    //for one row forms
    gap: 0;
  }

  &.submitting {
    input,
    button {
      pointer-events: none;
    }

    button.action::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      right: 10px;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: #000;
      animation: spinner 0.6s linear infinite;
    }
  }

  .row {
    position: relative;

    > label {
      font-size: 0.8em;
      color: var(--dark-gray-color);
    }

    input[type='text'],
    input[type='email'],
    input[type='password'] {
      border: solid 1px var(--border-color);
      background-color: var(--gray-bg-color);
      padding: 2px 10px;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    button {
      line-height: 2em;
      display: block;
      width: 100%;
    }

    &.inline {
      display: flex;
      gap: 10px;

      input[type='text'],
      input[type='email'],
      input[type='password'] {
        width: 100%;
      }
      button {
        width: max-content;
        padding: 0px 40px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  * {
    font-size: 14px;
  }
} /*# sourceMappingURL=index.css.map */
